.Error {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    padding: 10rem 0;
    color: #fff;
    background-color: #333;
    button {
        padding: 10px 20px;
        border: none;
        background-color: #fff;
        box-shadow: 5px 5px 15px 0px rgba(255, 255, 255, 0.2);
        border-radius: 5px;
        transition: 0.2s;
        &:hover {
            transform: translateY(5px);
            box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.2);
        }
        a {
            text-decoration: none;
            color: #333;
            font-size: 20px;
            font-weight: 600;
        }
    }
}
