// ------------------------ Section one ------------------------

.innovations-sectionOne-block-one {
  padding-top: 15rem;

  height: 50rem;
  background-image: url("../../../img//Inovation/Bg-02.png");
  background-position: 150px 0;
  background-size: cover;

  #parallax_img {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      position: absolute;
      transition: 0.1s;
      transform-style: preserve-3d;
    }

    span:nth-child(1) {
      top: -6rem !important;
      left: 0 !important;
      position: relative;

      img {
        width: 14rem;
        position: absolute;
        top: 45%;
        left: 8%;
      }

      svg {
        width: 60% !important;
      }
    }

    span:nth-child(2) {
      top: 13rem !important;
      left: -2rem !important;
      h4 {
        color: #fff;
        position: absolute;
        top: 45%;
        left: 14%;
      }

      svg {
        width: 45% !important;
      }
    }

    span:nth-child(3) {
      top: 20rem !important;
      left: 17rem !important;
      h4 {
        color: #fff;
        position: absolute;
        top: 45%;
        left: 7%;
      }

      svg {
        width: 45% !important;
      }
    }
  }

  .center-align {
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
      h2 {
        color: #fff;
        font-size: 50px;
        font-weight: 500;
        letter-spacing: 1.5px;
      }
    }
  }
}

// ------------------------ Section Two ------------------------

.innovations-sectionTwo-block-two {
  display: flex;
  align-items: center;
  justify-content: center;

  .center-align {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .title {
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    h2 {
      text-align: center;
      font-weight: 700;
      font-size: 30px;
      color: #495057;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &::after {
        content: "";
        width: 5%;
        height: 3px;
        border-radius: 20px;
        background-color: var(--primaryColor);
        position: absolute;
        bottom: 0;
      }
    }
  }
  .full-img {
    width: 14rem;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }

  .sub-title {
    margin-bottom: 1rem;
    width: 100%;

    position: relative;

    h5 {
      font-weight: 700;
      color: #495057;
      width: 100%;
      text-align: left;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      text-align: justify;
      width: 75%;
      color: #495057eb;
      font-size: 18px;
      line-height: 32px;
    }
  }
}

// ------------------------ Section Three ------------------------

.innovations-sectionThree-block-one {
  display: flex;
  align-items: center;
  justify-content: center;

  .center-align {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .title {
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    h2 {
      text-align: center;
      font-weight: 700;
      font-size: 30px;
      color: #495057;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &::after {
        content: "";
        width: 5%;
        height: 3px;
        border-radius: 20px;
        background-color: var(--primaryColor);
        position: absolute;
        bottom: 0;
      }
    }
  }

  .sub-title {
    margin-bottom: 1rem;
    width: 50%;

    position: relative;

    h5 {
      font-weight: 700;
      color: #495057;
      width: 100%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    p {
      text-align: justify;
      width: 75%;
      color: #495057eb;
      font-size: 18px;
      line-height: 32px;
    }
  }

  .icons-block {
    .title {
      margin-bottom: 2rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      h2 {
        text-align: center;
        font-weight: 700;
        font-size: 30px;
        color: #495057;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
          content: "";
          width: 5%;
          height: 3px;
          border-radius: 20px;
          background-color: var(--primaryColor);
          position: absolute;
          bottom: 0;
        }
      }
    }

    .sub-title {
      margin-bottom: 0;
      width: 100%;

      position: relative;

      h5 {
        font-weight: 700;
        color: #495057;
        width: 100%;
        text-align: center;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      p {
        text-align: justify;
        width: 75%;
        color: #495057eb;
        font-size: 18px;
        line-height: 32px;
      }
    }

    .icons-section {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10%;

      .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        gap: 30px;
        height: 10rem;

        span {
          font-size: 50px;
          opacity: 0.4;
          transition: 0.4s;
          cursor: pointer;

          &:hover {
            color: var(--primaryColor);
            opacity: 1;
          }
        }

        h4 {
          text-align: center;
          span {
            font-size: 20px;
            cursor: context-menu;
            opacity: 1;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            font-weight: 700;
          }
          span.blue {
            color: var(--secondaryColor);
          }
          span.red {
            color: var(--primaryColor);
          }
        }
      }
    }
    .list {
      display: flex;
      align-items: center;
      justify-content: center;

      ul {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        li {
          font-size: 18px;
          color: #495057eb;
        }
      }
    }
  }
}

// ------------------------ Section four ------------------------

.innovations-sectionFour-block-one {
  .title {
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    h2 {
      text-align: center;
      font-weight: 700;
      font-size: 30px;
      color: #495057;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &::after {
        content: "";
        width: 5%;
        height: 3px;
        border-radius: 20px;
        background-color: var(--primaryColor);
        position: absolute;
        bottom: 0;
      }
    }
    .mixed-sec {
      img {
        width: 14rem;
        margin: 0 10px;
      }
    }
  }

  .row {
    align-items: center;

    .list {
      ul {
        list-style: none;
        padding: 0;

        li {
          padding: 5px 0;
          display: flex;
          align-items: flex-start;
          gap: 20px;

          span {
            display: flex;
            font-size: 20px;
            margin-top: 3px;
            color: var(--primaryColor);

            img {
              width: 25px;
              height: 25px;
            }
          }

          p {
            color: #495057eb;
          }
        }
      }
    }

    .center-align {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .full-img {
      img {
        width: 100%;
      }
    }
  }
}

// ------------------------ Section five ------------------------

.innovations-sectionFive-block-one {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../../img/Inovation/Bg-01.jpg");
  background-position: center;
  background-size: cover;

  .comingSoon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    svg {
      width: 35rem !important;
    }

    span {
      position: absolute;

      .title {
        h2 {
          text-align: center;
          color: var(--primaryColor);
          letter-spacing: 3px;
          font-weight: 700;
          text-transform: uppercase;
        }
      }

      .sub-title {
        h3 {
          color: #fff;
          text-align: center;
          letter-spacing: 8px;
        }
      }
    }
  }
}

// ------------------------ Media Query ------------------------

@media (min-width: 1500px) {
  .innovations-sectionOne-block-one {
    height: 52rem;
  }
}

@media (max-width: 1300px) {
  .innovations-sectionOne-block-one {
    padding-top: 10rem;
    height: 40rem;
    background-position: 100px 0;
    .center-align {
      .title {
        h2 {
          font-size: 45px;
        }
      }
    }
    #parallax_img {
      span {
        &:nth-child(1) {
          top: -4rem !important;
          left: 5rem !important;
          img {
            width: 10rem;
            left: 6%;
          }
          svg {
            width: 45% !important;
          }
        }
        &:nth-child(2) {
          top: 10rem !important;
          h4 {
            left: 8%;
          }
          svg {
            width: 35% !important;
          }
        }
        &:nth-child(3) {
          top: 15rem !important;
          h4 {
            font-size: 20px;
            left: 6%;
          }
        }
      }
    }
  }
  .innovations-sectionFour-block-one {
    .row {
      .full-img {
        width: 80%;
      }
    }
  }
}

@media (max-width: 1025px) {
  .innovations-sectionOne-block-one {
    #parallax_img {
      span {
        &:nth-child(1) {
          left: 10rem !important;
        }
        &:nth-child(2) {
          left: 2rem !important;
        }
        &:nth-child(3) {
          h4 {
            left: 8%;
          }
          svg {
            width: 85% !important;
          }
        }
      }
    }
  }
  .innovations-sectionTwo-block-two {
    .full-img {
      margin-bottom: 2rem;
    }
  }
  .innovations-sectionThree-block-one {
    .icons-block {
      .icons-section {
        gap: 5%;
      }
    }
  }
  .innovations-sectionFour-block-one {
    .row {
      .list {
        ul {
          li {
            gap: 15px;
            span {
              img {
                width: 20px;
                width: 20px;
              }
            }
          }
        }
      }
    }
  }
  .innovations-sectionFive-block-one {
    .comingSoon {
      svg {
        width: 30rem !important;
      }
    }
  }
}
@media (max-width: 800px) {
  .innovations-sectionOne-block-one {
    display: flex;
    align-items: center;
    padding-top: 4rem;
    height: 35rem;
    background-position: -15rem 0;
    overflow: hidden;
    #parallax_img {
      span {
        &:nth-child(1) {
          top: -2rem !important;
          img {
            width: 8rem;
            left: 2%;
          }
          svg {
            width: 30% !important;
          }
        }
        &:nth-child(2) {
          top: -2rem !important;
          img {
            width: 8rem;
            left: 2%;
          }
          svg {
            width: 30% !important;
          }
        }
        &:nth-child(3) {
          top: -2rem !important;
          left: 30rem !important;
          img {
            width: 8rem;
            left: 2%;
          }
          svg {
            width: 75% !important;
          }
        }
      }
    }
    .title {
      h2 {
        text-align: center;
      }
    }
  }
  .innovations-sectionTwo-block-two {
    .sub-title {
      margin: 1rem 0;
      h5 {
        text-align: center;
      }
    }
    .content {
      p {
        width: 100%;
      }
    }
    .lottie-img {
      div {
        display: flex;
        justify-content: center;
        svg {
          width: 80% !important;
        }
      }
    }
  }
  .innovations-sectionThree-block-one {
    .sub-title {
      margin: 1rem 0;
      h5 {
        text-align: center;
      }
    }
    .content {
      p {
        width: 100%;
      }
    }
    .icons-block {
      .content p {
        width: 100%;
      }
    }
    .lottie-img {
      div {
        display: flex;
        justify-content: center;
        svg {
          width: 80% !important;
        }
      }
    }
  }
  .innovations-sectionFive-block-one {
    overflow: hidden;
  }
}

@media (max-width: 500px) {
  .innovations-sectionOne-block-one {
    height: 35rem;
    background-position: -35rem 40rem;
    #parallax_img {
      display: none;
    }
    .center-align {
      .title {
        h2 {
          font-size: 30px;
        }
      }
    }
  }
  .innovations-sectionThree-block-one {
    .icons-block {
      .icons-section {
        flex-direction: column;
      }
    }
  }
  .innovations-sectionFour-block-one {
    .row {
      .full-img {
        width: 100%;
      }
    }
  }
  .innovations-sectionFive-block-one {
    .comingSoon {
      svg {
        width: 25rem !important;
      }
    }
  }
}
@media (max-width: 400px) {
  .innovations-sectionFour-block-one {
    .title {
      .mixed-sec {
        img {
          width: 10rem;
        }
      }
    }
  }
}
