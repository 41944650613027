/* Totop */
.totop {
	position: fixed;
	bottom: -100px;
	right: 40px;
	width: 50px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	z-index: 9999;
	background-color: #222222;
	font-size: 20px;
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.42);
	transition: 0.4s;
	visibility: hidden;
	opacity: 0;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	&::before {
		content: "Back to Top";
		position: absolute;
		background-color: #222222;
		width: 100px;
		height: 20px;
		font-size: 14px;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		right: 60px;
		opacity: 0;
		visibility: hidden;
		transition: 0.4s;
		border-radius: 20px;
		transition-delay: 0;
		pointer-events: none;
	}
	&:hover {
		&:before {
			visibility: visible;
			opacity: 0.7;
			width: 100px;
			transition-delay: 0.3s;
		}
		background-color: #c81f3d;
		color: #fff;
		border-color: #c81f3d;
		color: #fff;
	}
}
.totop.active {
	visibility: visible;
	opacity: 1;
	bottom: 20px;
}
