// ---------------------------- Header ----------------------------
nav.header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9;
  background-color: transparent;
  width: 100%;
  transition: 0.4s;
  padding: 0 50px;
  .center-align {
    align-items: center;
  }
  .logo {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    img {
      width: 40%;
    }
  }

  // ---------------------------- Pc Menu ----------------------------

  .pc-nav-menu {
    @media (min-width: 800px) {
      display: flex;
    }
    @media (max-width: 799px) {
      display: none;
    }
    justify-content: end;
    ul.menu {
      display: flex;
      gap: 30px;
      list-style: none;
      padding: 0 !important;
      margin: 0 !important;
      li.main-menu {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        transition: 0.4s;
        cursor: pointer;
        z-index: 99;
        height: 50px;
        &:hover {
          color: #c81f3d;
          &::after {
            opacity: 1;
            visibility: visible;
            bottom: -0px;
            height: 3px;
          }
        }
        &::after {
          content: "";
          display: block;
          position: absolute;
          bottom: 10px;
          height: 4px;
          width: 100%;
          opacity: 0;
          background-color: var(--primaryColor);
          visibility: hidden;
          transition: 0.3s;
        }
        .main-nav-link {
          color: #fff;
          font-size: 15px;
          font-weight: 700;
          letter-spacing: 1.2px;
          text-decoration: none;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      li {
        ul.sub-menu {
          position: absolute;
          top: -47px;
          width: 265px;
          height: 0;
          padding: 0;
          margin: 0;
          list-style: none;
          box-shadow: 0 0 10px rgb(0 0 0 / 28%);
          background-color: #fff;
          overflow: hidden;
          li {
            width: 100%;
            .nav-link {
              color: #000;
              text-decoration: none;
              padding: 15px;
              text-transform: capitalize;
              font-size: 14px;
              font-weight: 700;
              letter-spacing: 0;
              transition: 0.4s;
              overflow: hidden;
              &::before {
                content: "/ /";
                color: #c81f3d;
                position: absolute;
                display: block;
                width: 100%;
                left: 13px;
                opacity: 0;
                transition: 0.5s;
              }
            }
            &:hover {
              .nav-link {
                color: #c81f3d;
                padding-left: 30px;
                &::before {
                  opacity: 1;
                }
              }
            }
          }
        }
        &:hover {
          ul.sub-menu {
            top: 50px;
            visibility: visible;
            opacity: 1;
            height: fit-content;
          }
        }
        &:last-child {
          ul.sub-menu {
            right: 0;
          }
        }
      }
    }
  }

  // ---------------------------- Tab & Mobile Menu ----------------------------
  .tab-nav-menu {
    @media (min-width: 800px) {
      display: none;
    }
    @media (max-width: 799px) {
      display: flex;
    }
    align-items: center;
    justify-content: flex-end;
    .menu-open-button {
      position: relative;
      span {
        width: 30px;
        height: 3px;
        background: var(--primaryColor);
        display: block;
        border-radius: 5px;
        transition: 0.4s;
        &:nth-child(1) {
          transform: translateY(-5px);
        }
        &:nth-child(2) {
          transform: scale(1);
          transition-delay: 0;
        }
        &:nth-child(3) {
          transform: translateY(5px);
          width: 20px;
        }
      }
    }
    .menu-open-button.active {
      span {
        &:nth-child(1) {
          transform: translate(0px);
          opacity: 0;
        }
        &:nth-child(2) {
          transform: scale(0);

          transition-delay: 0.4s;
        }
        &:nth-child(3) {
          transform: translate(0px);
          opacity: 0;
        }
      }
    }
  }
  .toggle-menu {
    background: var(--secondaryColor);
    position: absolute;
    top: 0;
    right: -50rem;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    transition: 0.6s;
    transition-delay: 0.5s;

    .menu-close-button {
      position: absolute;
      top: 10px;
      right: 30px;
      transition: 0.6s;
      span {
        color: var(--primaryColor);
        font-size: 35px;
      }
    }
    .menu-close-button.active {
      right: -100px;
    }

    ul.menu-list {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0 auto;
      width: 75%;
      margin-top: 5rem;
      gap: 5px;
      li.main-menu-title {
        .menu-link {
          display: flex;
          align-items: center;
          justify-content: space-between;
          a.main-nav-link {
            color: #fff;
            text-decoration: none;
            font-size: 18px;
          }
        }
        ul {
          height: 0;
          transition: 0.4s;
          background-color: #fff;
          padding: 0;
          margin: 0;
          li {
            border: 1px solid var(--secondaryColor);
            display: none;
            a {
              text-align: center;
              color: var(--primaryColor);
            }
          }
        }
        ul.active {
          height: fit-content;
          li {
            display: block;
          }
        }
        span {
          color: #fff;
          font-size: 30px;
        }
      }
    }
  }
  .toggle-menu.active {
    right: 0;
  }
}
nav.header.active {
  background-color: var(--secondaryColor);
  height: 100px;
  z-index: 99999;
}

// ---------------------------- Footer ----------------------------

.footer {
  background-color: var(--secondaryColor);
  color: #fff;
  padding: 50px 0;
  .foo-logo {
    img {
      width: 15%;
      margin-bottom: 40px;
    }
  }
  .foo-menu {
    h5 {
      margin-bottom: 20px;
      position: relative;
      &:after {
        content: "";
        width: 40px;
        height: 2px;
        position: absolute;
        display: inline-block;
        background-color: #c81f3d;
        bottom: -5px;
        left: 0;
      }
    }
    ul.sub-menu {
      list-style: none;
      padding: 0;
      li {
        .nav-link {
          color: #fff;
          padding: 5px 0 5px 0;
          font-size: 14px;
          font-weight: 400;
          width: fit-content;
          &:hover {
            color: #c81f3d;
          }
        }
      }
    }
  }
}
.copyright {
  color: #fff;
  padding: 20px;
  background-color: var(--secondaryColor);
  .copy {
    // padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    h5 {
      font-size: 16px;
      margin: 0;
    }
    a {
      color: #c81f3d;
      text-decoration: none;
      font-weight: 700;
    }
  }
}
.social-icon {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}
.icon-link {
  background-color: #fff;
  color: #000;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #c81f3d;
    .icon {
      color: #fff;
    }
  }
  .icon {
    font-size: 20px;
  }
}

// ---------------------------- Media Query ----------------------------

@media (max-width: 1301px) {
  nav.header {
    .pc-nav-menu {
      ul.menu {
        gap: 20px;
        li.main-menu {
          .main-nav-link {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media (max-width: 1220px) {
  nav.header {
    padding: 0 20px;
    .logo {
      img {
        width: 70%;
      }
    }
    .pc-nav-menu {
      ul.menu {
        li.main-menu {
          .main-nav-link {
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  nav.header {
    padding: 0 20px;
    .logo {
      img {
        width: 30%;
      }
    }
  }
  .footer {
    padding-bottom: 0;
    .foo-logo {
      img {
        width: 8%;
      }
    }
    .foo-des {
      p {
        margin-bottom: 2rem;
      }
    }
  }
  .copyright {
    .copy {
      padding: 2rem 0;
    }
  }
}

@media (max-width: 500px) {
  nav.header {
    .logo {
      img {
        width: 100px;
      }
    }
    .center-align {
      align-items: center;
      justify-content: space-between;
      .col-width {
        width: fit-content;
        padding: 0;
      }
    }
  }
  .footer {
    padding-bottom: 0;
    .foo-logo {
      img {
        width: 15%;
      }
    }
  }
}
