/* -------------------Section One------------------- */

.section-one-block {
  color: #fff;
  position: relative;
  // margin-bottom: 10rem;
  .welcome-inn-sec {
    margin-top: -300px !important;
    padding: 0 0 10px 50px;
  }
  .sticky-bg-image {
    position: sticky;
    top: 0;
    width: 100vw;
    // height: 50rem;
    z-index: -2;
    video {
      width: 100%;
    }
  }
  .sticky-section {
    h2 {
      position: sticky;
      top: 250px;
      margin-bottom: 100px;
      color: #fff;
      font-weight: 700;
      letter-spacing: 1.2px;
      &::before {
        content: "";
        position: absolute;
        width: 50%;
        height: 2px;
        background-color: #fff;
        border-radius: 10px;
        margin-top: -30px;
      }
    }
  }
  .scroll-section {
    padding-left: 0 !important;
    padding-right: 0 !important;
    position: relative;
    .mouse-icon {
      position: absolute;
      left: 50%;
      top: -30px;
      font-size: 22px;
      border: 2px solid #fff;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .scroll-section-inn-one {
      background-image: url("../../../img/home/welcome-bg.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 5rem;
      width: 100%;
      height: 100%;
      margin: 0;
      display: flex;
      align-items: center;
    }
    h5 {
      font-weight: 700;
      letter-spacing: 1.1px;
      text-align: center;
      margin-bottom: 70px;
    }
    p {
      font-size: 18px;
      text-align: justify;
    }
  }
  .scroll-section-inn-one {
    margin: 0 auto;
    .download-btn {
      color: #fff;
      text-decoration: none;
      border: 2px solid #fff;
      height: 40px;
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: center;
      border-radius: 3px;
      width: 32%;
      font-size: 16px;
      letter-spacing: 1.2px;
      font-weight: 700;
      padding: 0 25px;
      margin: 0 auto;
      span {
        &:nth-child(2) {
          font-size: 20px;
        }
      }
    }
    .scroll-section-inn-one-counter {
      margin-top: 70px;
      h4 {
        font-size: 54px;
      }
      p {
        font-size: 15px;
        font-weight: 700;
        line-height: 30px;
      }
    }
  }
  .scroll-section-inn-two {
    background-color: #fff;
    margin: 0 !important;
    color: #000;
    display: flex;
    align-items: center;
    .scroll-section-inn-two-banner {
      padding: 50px;
    }
    p {
      font-size: 17px;
      margin-bottom: 30px;
      color: #9b9b9b;
    }
    .inn-two {
      padding: 0;
      img {
        width: 100%;
      }
    }
    a {
      color: #495057;
      font-weight: 700;
      text-decoration: none;
      span {
        margin-left: 20px;
      }
    }
  }
  .scroll-section-inn-three {
    margin: 0 !important;
    display: flex;
    align-items: center;
    background-color: #fff;
    .inn-one {
      background-color: #c81f3d;
      padding: 90px 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      img {
        width: 70px;
        margin-bottom: 25px;
      }
      h4 {
        font-size: 20px;
        text-transform: capitalize;
        font-weight: 700;
        line-height: 1.3;
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 25px;
        line-height: 1.7;
      }
      a {
        color: #fff;
        font-weight: 700;
        text-decoration: none;
        span {
          margin-left: 20px;
        }
      }
    }
    .inn-two {
      padding: 40px;
      ul {
        padding: 0;
        list-style: none;
        li {
          line-height: 1.7;
          margin-bottom: 15px;
          font-size: 16px;
          color: #9b9b9b;
          display: flex;
          gap: 15px;
        }
      }
    }
  }
  .scroll-section-inn-four {
    padding: 110px 50px;
    margin: 0 !important;
    background-color: #122639;
    h3 {
      font-size: 37px;
    }
    p {
      line-height: 1.7;
    }
    .homeSwiper {
      .swiperCard {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        color: #000;
        width: 250px !important;
        border-radius: 5px;
        padding: 25px 35px 37px;
        cursor: default;
        position: relative;
        height: 20rem;
        &::before {
          content: "";
          position: absolute;
          background-color: #c81f3d;
          top: 0;
          width: 0;
          height: 3px;
          border-radius: 50px;
          transition: 0.5s;
        }

        .slide-img {
          margin-left: -100px;
          margin-bottom: 40px;
          width: 100px;
          padding: 10px;
        }
        img {
          // width: 100% !important;
          transition: 0.5s;
          -webkit-transition: 0.5s;
          -moz-transition: 0.5s;
          -o-transition: 0.5s;
          min-height: 50px;
        }
        p {
          font-size: 16px;
          color: #9b9b9b;
          min-height: 100px;
          text-align: left;
        }
        &:hover {
          &:before {
            width: 80%;
          }
          img {
            transform: rotateY(360deg);
            -moz-transform: rotateY(360deg);
            -o-transform: rotateY(360deg);
            -ms-transform: rotateY(360deg);
            -webkit-transform: rotateY(360deg);
          }
        }
      }
    }
  }
  .swiper-pagination-bullet {
    opacity: 1 !important;
    background-color: #fff !important;
  }
  .swiper-pagination-bullet-active {
    background-color: #c81f3d !important;
  }
  .swiper {
    width: 100%;
    height: 23.6rem;
  }
}

/* -------------------Section Two------------------- */

.section-two-block {
  margin-bottom: 2rem;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      text-align: center;
      height: 5rem;
      display: flex;
      width: 100%;
      font-weight: 700;
      justify-content: center;
      position: relative;
      color: #495057;
      &::after {
        content: "";
        width: 5%;
        height: 3px;
        border-radius: 20px;
        background-color: var(--primaryColor);
        position: absolute;
        bottom: 30px;
      }
    }
  }
  .bloc-tabs {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 50px;
    margin-top: 5px;
    padding-right: 2rem;
  }
  .tab-view {
    display: flex;
    position: relative;
    background-image: url("../../../img/home/bg-globe.png");
    background-position: 617px 143px;
    background-repeat: no-repeat;
    background-size: 50px;

    &::before {
      content: "";
      position: absolute;
      top: -26px;
      width: 100%;
      height: 22rem;
      opacity: 0.8;
      background-image: url("../../../img/home/Bg-01.png");
      background-position: center;
      background-size: 75%;
      background-repeat: no-repeat;
      z-index: -9;
    }
  }
  .tabs {
    width: fit-content;
    text-align: right;
    background: transparent;
    cursor: pointer;
    box-sizing: content-box;
    position: relative;
    outline: none;
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 1.2px;
    display: flex;
    justify-content: flex-end;
    span {
      font-size: 10px;
      transition: 0.4s;
      margin-left: 10px;
    }
  }
  .active-tabs {
    color: #c81f3d;
    span {
      transform: rotate(45deg);
    }
  }
  button {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content {
    padding-top: 30px;
    // width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    transition: 0.7s;
    opacity: 0;
    ul {
      list-style: circle;
      line-height: 40px;
      padding: 0;
      li {
        font-size: 20px;
      }
    }
  }
  .active-content {
    opacity: 1;
    transition-delay: 0.3s;
  }
}

/* -------------------Section Three------------------- */

.section-three-block {
  margin: 5rem 0;
  position: relative;
  overflow: hidden;
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      text-align: center;
      height: 5rem;
      display: flex;
      width: 100%;
      font-weight: 700;
      justify-content: center;
      position: relative;
      color: #495057;
      &::after {
        content: "";
        width: 5%;
        height: 3px;
        border-radius: 20px;
        background-color: var(--primaryColor);
        position: absolute;
        bottom: 30px;
      }
    }
  }
  .full-img {
    width: 100%;
    img {
      width: 100%;
    }
  }
}

/* -------------------Section Four------------------- */

.home-section-four {
  .title {
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    h2 {
      text-align: center;
      font-weight: 700;
      font-size: 30px;
      color: #495057;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &::after {
        content: "";
        width: 5%;
        height: 3px;
        border-radius: 20px;
        background-color: var(--primaryColor);
        position: absolute;
        bottom: 0;
      }
    }
  }
  .images {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 60px;
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      padding: 0;
      margin: 0;
      gap: 80px;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--secondaryColor);
        box-shadow: 10px 10px 15px 2px rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        width: 12rem;
        height: 12rem;

        img {
          width: 60%;
        }
      }
      li {
        &:nth-child(1) {
          img {
            animation: move1 infinite 2s;
            @keyframes move1 {
              0% {
                transform: translate(5px, 5px);
              }
              50% {
                transform: translate(0px, 0px);
              }
              100% {
                transform: translate(5px, 5px);
              }
            }
          }
        }
        &:nth-child(2) {
          img {
            animation: move2 infinite 2s;
            @keyframes move2 {
              0% {
                transform: translate(5px, -5px);
              }
              50% {
                transform: translate(0px, 0px);
              }
              100% {
                transform: translate(5px, -5px);
              }
            }
          }
        }
        &:nth-child(3) {
          img {
            animation: move3 infinite 2s;
            @keyframes move3 {
              0% {
                transform: translate(0, 10px);
              }
              50% {
                transform: translate(0px, 0px);
              }
              100% {
                transform: translate(0, 10px);
              }
            }
          }
        }
        &:nth-child(4) {
          img {
            animation: move4 infinite 2s;
            @keyframes move4 {
              0% {
                transform: translate(-5px, -5px);
              }
              50% {
                transform: translate(0px, 0px);
              }
              100% {
                transform: translate(-5px, -5px);
              }
            }
          }
        }
        &:nth-child(5) {
          img {
            animation: move5 infinite 2s;
            @keyframes move5 {
              0% {
                transform: translate(-5px, 5px);
              }
              50% {
                transform: translate(0px, 0px);
              }
              100% {
                transform: translate(-5px, 5px);
              }
            }
          }
        }
      }
    }
  }
}

// -------------------Section Five-------------------

.home-section-five {
  .container {
    position: relative;
  }

  .title {
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    h2 {
      text-align: center;
      font-weight: 700;
      font-size: 30px;
      color: #495057;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &::after {
        content: "";
        width: 5%;
        height: 3px;
        border-radius: 20px;
        background-color: var(--primaryColor);
        position: absolute;
        bottom: 0;
      }
    }
  }
  .pc-view {
    @media (min-width: 800px) {
      display: block;
    }
    @media (max-width: 799px) {
      display: none;
    }
    .full-svg {
      position: absolute;
      z-index: -99;
      // width: 100%;
      left: 28%;
      div {
        svg {
          width: 35rem !important;
        }
      }
    }
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36rem;
      .align-center {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .left-section {
        .inner-section {
          display: flex;
          flex-direction: column;
          gap: 200px;
          .box {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            gap: 5px;
            p {
              text-align: right;
              width: 70%;
            }
            span {
              border-radius: 50px;
              padding: 15px;
              border: 5px solid transparent;
              width: 100px;
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 0.4s;
              margin-top: -20px;
              img {
                width: 100%;
                filter: brightness(0) saturate(100%) invert(11%) sepia(17%) saturate(2370%) hue-rotate(168deg)
                  brightness(92%) contrast(91%);
                cursor: pointer;
              }
              &:hover {
                transform: scale(1.2);
                img {
                  filter: brightness(0) saturate(100%) invert(20%) sepia(98%) saturate(3102%) hue-rotate(338deg)
                    brightness(80%) contrast(93%);
                }
              }
            }
          }
        }
      }
      .mid-section {
        p {
          text-align: center;
          color: #fff;
          width: 75%;
          font-size: 18px;
          margin: 0 auto;
        }
      }
      .right-section {
        .inner-section {
          display: flex;
          flex-direction: column;
          gap: 200px;
          .box {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            position: relative;
            gap: 5px;
            p {
              text-align: left;
              width: 70%;
            }
            span {
              border-radius: 50px;
              padding: 15px;
              border: 5px solid transparent;
              width: 100px;
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 0.4s;
              margin-top: -20px;
              img {
                width: 100%;
                filter: brightness(0) saturate(100%) invert(11%) sepia(17%) saturate(2370%) hue-rotate(168deg)
                  brightness(92%) contrast(91%);
                cursor: pointer;
              }
              &:hover {
                transform: scale(1.2);
                img {
                  filter: brightness(0) saturate(100%) invert(20%) sepia(98%) saturate(3102%) hue-rotate(338deg)
                    brightness(80%) contrast(93%);
                }
              }
            }
          }
        }
      }
    }
  }
  .tab-view {
    @media (min-width: 800px) {
      display: none;
    }
    @media (max-width: 799px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 30px;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      // gap: 40px;
      height: 100px;
      width: 100%;
      span {
        img {
          width: 60px;
          filter: brightness(0) saturate(100%) invert(20%) sepia(98%) saturate(3102%) hue-rotate(338deg) brightness(80%)
            contrast(93%);
        }
      }
      p {
        width: 20rem;
        text-align: justify;
      }
    }
  }
}

// ------------------ Media Query ------------------

@media (min-width: 1680px) {
  .section-one-block {
    margin-bottom: 15rem;
  }
}

@media (min-width: 1500px) {
  // .section-one-block {
  //   margin-bottom: 10rem;
  // }
}

@media (max-width: 1500px) {
  .section-one-block {
    margin-bottom: 5rem;
  }
}
@media (max-width: 1300px) {
  .section-one-block {
    .sticky-bg-image {
      overflow: hidden;
      background: linear-gradient(to bottom, var(--secondaryColor), #fff);
      video {
        width: 118%;
        height: 54rem;
      }
    }
    .sticky-section {
      h2 {
        font-size: 30px;
      }
    }
    .scroll-section {
      h5 {
        margin-bottom: 50px;
      }
    }
    .scroll-section-inn-one {
      .download-btn {
        width: 45%;
      }
    }
    .scroll-section-inn-two {
      .inn-two {
        img {
          height: 36rem;
        }
      }
    }
    .scroll-section-inn-two-banner {
      padding: 30px;
    }
    .scroll-section-inn-three {
      .inn-one {
        padding: 50px 20px;
      }
    }
    .scroll-section-inn-four {
      padding: 60px 20px;
    }
  }
  .section-two-block {
    .tab-view {
      background-position: 530px 140px;
    }
    .bloc-tabs {
      gap: 40px;
      margin-top: 24px;
    }
  }
  .home-section-four {
    .images {
      gap: 40px;
      ul {
        gap: 50px;
        li {
          img {
            width: 55%;
          }
        }
      }
    }
  }
  .home-section-five {
    .full-svg {
      left: 25%;
    }
  }
}

@media (max-width: 1025px) {
  .section-one-block {
    .sticky-bg-image {
      overflow: hidden;
      video {
        width: 166%;
        height: 60rem;
      }
    }
    .scroll-section {
      p {
        font-size: 16px;
      }
      .mouse-icon {
        left: 45%;
      }
      .scroll-section-inn-one {
        padding: 5rem 2rem;
        .download-btn {
          width: 50%;
        }
      }
    }
    .scroll-section-inn-two {
      .scroll-section-inn-two-banner {
        padding: 10px;
      }
      .inn-two {
        img {
          height: 28rem;
        }
      }
    }
    .scroll-section-inn-three {
      .inn-one {
        padding: 10px;
      }
      .inn-two {
        padding: 10px;
      }
    }
    .scroll-section-inn-four {
      h3 {
        font-size: 26px;
      }
    }
  }
  .section-two-block {
    .tab-view {
      background-position: 440px 140px;
      background-size: 48px;
    }
    .bloc-tabs {
      gap: 30px;
      margin-top: 42px;
    }
  }
  .home-section-four {
    .images {
      ul {
        li {
          width: 8rem;
          height: 8rem;
        }
      }
    }
  }
  .home-section-five {
    .full-svg {
      left: 20% !important;
    }
  }
}

@media (max-width: 900px) {
  .section-one-block {
    margin: 0;
    .welcome-inn-sec {
      padding: 0 50px 10px 50px;
    }
    .sticky-bg-image {
      video {
        width: 222%;
      }
    }
  }
  .section-two-block {
    .bloc-tabs {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0;
      gap: 15px;
    }
    .tab-view {
      background-image: none;
      flex-direction: column;
      &::before {
        background-image: none;
      }
    }
    .content-tabs {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 10rem;
      margin-top: 3rem;
    }
    .content {
      padding: 0;
      height: fit-content;
    }
    .tabs {
      flex-direction: column;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      span {
        display: none;
      }
    }
  }
  .home-section-four {
    .images {
      gap: 20px;
      ul {
        gap: 20px;
      }
    }
  }
}

@media (max-width: 500px) {
  .section-one-block {
    .sticky-bg-image {
      // height: 56rem;
      video {
        width: 319%;
        height: 56rem;
      }
    }
    .sticky-section {
      h2 {
        font-size: 17px;
        text-align: justify;
        font-weight: 500;
        line-height: 28px;
      }
    }
    .scroll-section {
      .mouse-icon {
        left: 40%;
      }
      h5 {
        margin-bottom: 20px;
      }
      .scroll-section-inn-one {
        padding: 2rem 1rem;
        .download-btn {
          width: 100%;
          justify-content: space-between;
        }
      }
      .scroll-section-inn-two {
        .scroll-section-inn-two-banner {
          padding: 2rem 1rem;
        }
        a {
          float: right;
        }
        .inn-two {
          display: none;
        }
      }
      .scroll-section-inn-three {
        .inn-one {
          padding: 2rem 1rem;
          a {
            text-align: right;
          }
        }
        .inn-two {
          padding: 2rem 1rem;
        }
      }
    }
  }
  .section-two-block {
    .bloc-tabs {
      // flex-direction: column;
      align-items: center;
      gap: 22px;
      background: var(--secondaryColor);
      padding: 2rem 0;
    }
    .tabs {
      flex-direction: row;
      color: #fff;
    }
    .tabs.active-tabs {
      color: var(--primaryColor);
      font-weight: 700;
    }
    .content-tabs {
      margin-top: 1rem;
    }
    .content {
      ul {
        list-style: none;
        li {
          text-align: center;
        }
      }
    }
  }
  .home-section-four {
    .images {
      flex-direction: row;
      gap: 10px;
      ul {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}

@media (max-width: 400px) {
  .section-one-block {
    .welcome-inn-sec {
      padding: 0 50px 100px 50px;
    }

    .scroll-section {
      h5 {
        text-align: justify;
      }
      // .scroll-section-inn-one {
      //   .download-btn {
      //     width: 100%;
      //     justify-content: space-between;
      //   }
      // }
    }
  }
  .home-section-four {
    .images {
      ul {
        li {
          width: 7rem;
          height: 7rem;
        }
      }
    }
  }
  .home-section-five {
    .tab-view {
      .box {
        p {
          width: 16rem;
        }
      }
    }
  }
}
