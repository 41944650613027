// Slider-Section
.penetration-slider {
  position: relative;

  .slider-text {
    position: absolute;
    top: 50%;

    h1 {
      color: #fff;
      text-align: right;
      font-weight: 700;
      font-size: 50px;
      letter-spacing: 1.5px;
    }
  }
}

// ---------------------- Section one ----------------------
.penetration-section-one {
  .welcome-block {
    color: #000;
    .title {
      margin: 3rem 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      h2 {
        text-align: center;
        font-size: 30px;
        font-weight: 700;
        color: #495057;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after {
          content: "";
          width: 5%;
          height: 3px;
          border-radius: 20px;
          background-color: var(--primaryColor);
          position: absolute;
          bottom: 0;
        }
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        text-align: justify;
        width: 80%;
        margin: 0 auto;
        color: #495057eb;
        font-size: 18px;
        line-height: 35px;
      }
    }
    .icons-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      gap: 75px;

      .box {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .inn-box {
          display: flex;
          align-items: center;
          width: 15rem;
          height: 8rem;

          span {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 50%;
              filter: brightness(0) saturate(100%) invert(20%) sepia(98%) saturate(3102%) hue-rotate(338deg)
                brightness(80%) contrast(93%);
            }
          }
          p {
            display: flex;
            align-items: center;
            width: 135px;
            margin: 0;
            font-weight: 700;
            color: #495057eb;
            letter-spacing: 1.3px;
          }
        }
      }
    }
  }

  .section-one-inn-block-two {
    color: #000;
    margin-top: 5rem;
    .center-align {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .sub-title {
      margin-bottom: 3rem;
      width: 100%;
      display: flex;
      position: relative;
      h5 {
        font-weight: 700;
        text-align: center;
        color: #495057;
        width: 100%;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        text-align: justify;
        width: 100%;
        color: #495057eb;
        font-size: 18px;
        line-height: 35px;
      }
    }
    .title {
      margin-bottom: 3rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      h2 {
        text-align: center;
        font-size: 30px;
        font-weight: 700;
        color: #495057;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after {
          content: "";
          width: 5%;
          height: 3px;
          border-radius: 20px;
          background-color: var(--primaryColor);
          position: absolute;
          bottom: 0;
        }
      }
    }

    .icons-slider {
      height: 32rem;
      .swiper-slide {
        display: block;
      }
      .swiper-pagination-bullet {
        border: 1px solid var(--secondaryColor) !important;
        width: 12px;
        height: 12px;
      }
      .swiper-pagination-bullet-active {
        background-color: var(--primaryColor) !important;
        border-color: var(--primaryColor) !important;
      }
      .swiper-pagination {
        bottom: -5px !important;
      }
    }

    .icons-box {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      gap: 60px;
      cursor: grab;
      &:focus,
      :active {
        cursor: grabbing;
      }
      .box {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .inn-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 20px;
          span {
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 55%;
              filter: brightness(0) saturate(100%) invert(20%) sepia(98%) saturate(3102%) hue-rotate(338deg)
                brightness(80%) contrast(93%);
            }
          }
          p {
            text-align: center;
            height: 100px;
            font-size: 15px;
            width: 100px;
            margin: 0;
            color: #495057;
            letter-spacing: 1.3px;
          }
        }
      }
    }
  }

  .tab-nav {
    padding: 0;
    ul {
      list-style: none;
      display: flex;
      padding: 0;
      margin: 0;
      align-items: center;
      justify-content: center;
      gap: 40px;
      height: 60px;
      transition: 0.4s;
      li {
        color: var(--primaryColor);
        cursor: pointer;
        font-size: 16px;
        font-weight: 700;
        position: relative;
        height: 40px;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scale(0);
          height: 2px;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--primaryColor);
          transition: 0.4s;
        }
        &:hover::after {
          transform: scale(1);
        }

        a {
          width: 100%;
          height: 100%;
          text-decoration: none;
          color: var(--primaryColor);
          display: flex;
          align-items: center;
        }
      }
      li.active {
        &::after {
          transform: scale(1);
        }
      }
    }
    ul.active {
      position: fixed;
      background-color: var(--secondaryColor);
      width: 100%;
      // height: fit-content;
      top: 100px;
      z-index: 9;

      li {
        color: #fff;
        a {
          color: #fff;
        }
      }
    }
  }

  .tab-content {
    color: #000;
    margin-top: 5rem;
    display: none;
    .title {
      margin: 3rem 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      h2 {
        text-align: center;
        font-weight: 700;
        font-size: 30px;
        color: #495057;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after {
          content: "";
          width: 5%;
          height: 3px;
          border-radius: 20px;
          background-color: var(--primaryColor);
          position: absolute;
          bottom: 0;
        }
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        text-align: justify;
        width: 100%;
        color: #495057eb;
        font-size: 18px;
        line-height: 35px;
      }
    }
    .icons-section {
      .sub-title {
        margin-bottom: 2rem;
        h5 {
          text-align: center;
          font-weight: 700;
          color: #495057;
        }
      }
      .icons-box {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 60px;
        .box {
          display: flex;
          flex-direction: column;
          gap: 20px;
          .inn-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 20px;
            span {
              width: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 55%;
                filter: brightness(0) saturate(100%) invert(20%) sepia(98%) saturate(3102%) hue-rotate(338deg)
                  brightness(80%) contrast(93%);
              }
            }
            p {
              text-align: center;
              height: 100px;
              font-size: 15px;

              margin: 0;
              color: #495057;
              letter-spacing: 1.3px;
            }
          }
        }
      }
    }
  }
  .tab-content.active {
    display: block;
  }
}

// ---------------------- Section two ----------------------

.pen-section-two-block {
  margin-bottom: 5rem;
  overflow: hidden;
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3rem 0;
    h2 {
      color: #495057;
      font-weight: 700;
      text-align: center;
      height: 5rem;
      display: flex;
      font-size: 30px;
      justify-content: center;
      position: relative;
      &::after {
        content: "";
        width: 30%;
        height: 3px;
        border-radius: 20px;
        background-color: var(--primaryColor);
        position: absolute;
        bottom: 35px;
      }
    }
  }
  .testing {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
  .box {
    display: flex;
    flex-direction: column;
    width: 25%;
    align-items: center;
    height: 16rem;

    svg {
      width: 16rem !important;
      opacity: 0.9;
    }
    h6 {
      text-align: center;
      font-weight: 700;
      color: #495057;
      font-size: 22px;
    }
  }
  span {
    width: unset;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 10px;
    transition: 0.4s;
    opacity: 1;
  }
}

// ---------------------- Section three ----------------------

.pen-section-three-block {
  padding: 5rem 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),
    url("../../../img/penetration-testing/bg-01.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      text-align: center;
      height: 5rem;
      display: flex;
      font-weight: 700;
      font-size: 30px;
      justify-content: center;
      position: relative;
      color: #dee2e6;
      &::after {
        content: "";
        width: 30%;
        height: 3px;
        border-radius: 20px;
        background-color: var(--primaryColor);
        position: absolute;
        bottom: 35px;
      }
    }
  }
  .row:nth-child(2) {
    align-items: center;
    justify-content: center;

    .content {
      margin: 2rem 0;
      p {
        width: 90%;
        color: #dee2e6;
        font-size: 18px;
        line-height: 35px;
        text-align: justify;
      }
    }
    .formula {
      width: 75%;
      text-align: center;
      img {
        width: 70%;
        filter: brightness(0) invert(1);
      }
    }

    .life-cycle {
      .block-one,
      .block-two,
      .block-three {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        .circle {
          border: 2px solid #dee2e6;
          border-radius: 50%;
          padding: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 7rem;
          height: 7rem;
          cursor: pointer;
          position: relative;

          img {
            width: 60%;
            filter: brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(2387%) hue-rotate(176deg)
              brightness(92%) contrast(96%);
          }
          p {
            position: absolute;
            font-size: 14px;
            letter-spacing: 1.2px;
            color: #dee2e6;
            font-weight: 700;

            transform: translate(0, 100px);
            text-align: center;
          }
          &:hover {
            border-color: var(--primaryColor);
            img {
              filter: brightness(0) saturate(100%) invert(20%) sepia(98%) saturate(3102%) hue-rotate(338deg)
                brightness(80%) contrast(93%);
            }
            p {
              color: var(--primaryColor);
            }
          }
        }
      }
    }
    .block-one {
      span {
        background-color: #dee2e6;
        height: 2px;
        width: 50px;
      }
    }
    .block-two {
      justify-content: space-evenly !important;
      gap: 120px !important;
      margin: 6rem 0;
      span {
        background-color: #dee2e6;
        height: 60px;
        width: 2px;
      }
      h6 {
        font-size: 22px;
        text-align: center;
        font-weight: 700;
        color: #dee2e6;
      }
    }
    .block-three {
      .circle {
        p {
          transform: translateY(-80px) !important;
        }
      }
      span {
        background-color: #dee2e6;
        height: 2px;
        width: 50px;
      }
    }
  }
}

// ---------------------- Section four ----------------------
.pen-section-four-block {
  margin-bottom: 5rem;
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      text-align: center;
      height: 5rem;
      display: flex;
      font-weight: 700;
      font-size: 30px;
      justify-content: center;
      position: relative;
      color: #495057;
      &::after {
        content: "";
        width: 30%;
        height: 3px;
        border-radius: 20px;
        background-color: var(--primaryColor);
        position: absolute;
        bottom: 35px;
      }
    }
  }
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    img {
      width: 18%;
    }
    h4 {
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      color: #495057;
    }
  }
}

// ------------------------ Media query ------------------------

@media (max-width: 1300px) {
  .pen-section-three-block {
    .row:nth-child(2) {
      .life-cycle {
        .block-one {
          .circle {
            width: 10rem;
          }
        }
        .block-three {
          .circle {
            width: 10rem;
          }
        }
      }
    }
  }
}

@media (max-width: 1025px) {
  .pen-section-three-block {
    .row:nth-child(2) {
      .life-cycle {
        .block-one {
          .circle {
            width: 20rem;
            height: 6rem;
          }
        }
        .block-three {
          .circle {
            width: 20rem;
            height: 6rem;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .penetration-slider-section {
    height: 25rem;
    .slider-text {
      h1 {
        font-size: 35px;
      }
    }
  }
  .penetration-section-one {
    .welcome-block {
      .content {
        p {
          width: 100%;
        }
      }
    }
    .tab-nav {
      ul {
        gap: 20px;
        flex-wrap: wrap;
        li {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
  .pen-section-two-block {
    margin-bottom: 0;
    overflow: hidden;
    .testing {
      gap: 0;
      .box {
        svg {
          width: 10rem !important;
        }
        h6 {
          font-size: 20px;
        }
      }
    }
    span {
      margin-top: -80px;
    }
  }
  .pen-section-three-block {
    .row {
      &:nth-child(2) {
        .content {
          p {
            width: 100%;
          }
        }
        .formula {
          margin: 0 auto;
          margin-bottom: 3rem;
        }
        .life-cycle {
          .block-one {
            .circle {
              width: 6rem;
            }
          }
          .block-two {
            gap: 60px !important;
          }
          .block-three {
            .circle {
              width: 6rem;
            }
          }
        }
      }
    }
  }
  .pen-section-four-block {
    .box {
      img {
        width: 35%;
      }
    }
  }
}

@media (max-width: 500px) {
  .penetration-slider {
    .slider-text {
      h1 {
        text-align: center;
      }
    }
  }
  .penetration-section-one {
    .tab-nav {
      ul.active {
        height: fit-content;
      }
    }
    .section-one-inn-block-two {
      .icons-box {
        gap: 30px;
        .box {
          .inn-box {
            p {
              height: 60px;
              font-size: 14px;
            }
            span {
              img {
                width: 50%;
              }
            }
          }
        }
      }
    }
    .tab-content {
      .icons-section {
        .icons-box {
          gap: 30px;
          .box {
            .inn-box {
              p {
                height: 90px;
                font-size: 14px;
              }
              span {
                img {
                  width: 50%;
                }
              }
            }
          }
        }
      }
    }
  }
  .pen-section-two-block {
    .testing {
      flex-direction: column;
    }
    .box {
      height: 18rem;
    }
    span {
      transform: rotate(90deg);
    }
  }
  .pen-section-three-block {
    .row:nth-child(2) {
      .formula {
        img {
          width: 100%;
        }
      }
      .life-cycle {
        .block-one,
        .block-three {
          gap: 15px;
          .circle {
            width: 7rem;
          }
        }
      }
    }
  }
}
@media (max-width: 400px) {
  .penetration-section-one {
    .tab-nav {
      ul {
        height: fit-content;
      }
    }
    .tab-content {
      .icons-section {
        .icons-box {
          gap: 15px;
        }
      }
    }
  }
}
