// Banner Video
.banner-video {
    position: relative;
    height: 890px;
    background-color: #0e071c;
    video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .title {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.4);
        h1 {
            color: #fff;
            font-weight: 700;
            font-size: 45px;
            span {
                color: var(--primaryColor);
            }
        }
    }
}

// ---------------- Slider-Section ----------------
.offensive-slider {
    position: relative;

    .slider-text {
        position: absolute;
        top: 40%;

        h1 {
            color: #fff;
            text-align: center;
            font-weight: 700;
            font-size: 45px;
        }
    }
}
// ---------------- Section one ----------------

.offensive-section-one {
    .title {
        display: flex;
        align-items: center;
        justify-content: center;
        h2 {
            text-align: center;
            height: 5rem;
            display: flex;
            width: 100%;
            font-weight: 700;

            justify-content: center;
            position: relative;
            color: #495057;
            &::after {
                content: "";
                width: 5%;
                height: 3px;
                border-radius: 20px;
                background-color: var(--primaryColor);
                position: absolute;
                bottom: 30px;
            }
        }
    }
    .content {
        p {
            text-align: justify;
            width: 100%;
            color: #495057eb;
            font-size: 18px;
            line-height: 35px;
        }
    }
    .center-align {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .full-img {
        width: 80%;
        margin: 0 auto;
        img {
            width: 100%;
        }
    }
}

// ---------------- Section two ----------------

.offensive-section-two {
    .tab-button {
        margin-bottom: 5rem;
        position: relative;
        padding: 0;
        overflow: hidden;
        video {
            position: absolute;
            width: 100%;
            top: -20rem;
        }
        .box {
            padding: 0;

            &:nth-child(2) {
                border-left: 3px solid #fff;
                border-right: 3px solid #fff;
                z-index: 9;
            }

            .tab-link {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 20px;
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                padding: 5rem;
                position: relative;
                h2 {
                    color: #fff;
                    font-weight: 600;
                }
                p {
                    text-align: justify;
                    color: #fff;
                    font-size: 18px;
                }
                a {
                    border: none;
                    background-color: var(--primaryColor);
                    color: #fff;
                    padding: 10px 20px 10px 20px;
                    border-radius: 5px;
                    transition: 0.4s;
                    text-decoration: none;
                    &:hover {
                        opacity: 0.8;
                        transform: translateY(5px);
                    }
                }
                &::after {
                    content: "";
                    width: 100%;
                    height: 2px;
                    background-color: var(--primaryColor);
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transform: scale(0);
                    transition: 0.4s;
                }
            }
            .tab-link.active {
                &::after {
                    transform: scale(1);
                }
            }
        }
    }
    .tab-content {
        display: none;
        .title {
            display: flex;
            align-items: center;
            justify-content: center;
            h2 {
                text-align: center;
                height: 5rem;
                display: flex;
                width: 100%;
                font-weight: 700;

                justify-content: center;
                position: relative;
                color: #495057;
                &::after {
                    content: "";
                    width: 5%;
                    height: 3px;
                    border-radius: 20px;
                    background-color: var(--primaryColor);
                    position: absolute;
                    bottom: 30px;
                }
            }
        }
        .sub-title {
            margin-top: 2rem;
            h5 {
                text-align: center;
                font-weight: 700;
                color: #495057;
            }
        }
        .content {
            p {
                text-align: justify;
                width: 100%;
                color: #495057eb;
                font-size: 18px;
                line-height: 35px;
            }
        }
        .center-align {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .flex-col {
            flex-direction: column;
        }
        .icons-section {
            .sub-title {
                margin-bottom: 2rem;
                h5 {
                    text-align: center;
                    font-weight: 700;
                    color: #495057;
                }
            }
            .icons-box {
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                gap: 60px;
                .box {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    .inn-box {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        gap: 20px;
                        span {
                            width: 100px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            img {
                                width: 55%;
                                filter: brightness(0) saturate(100%) invert(20%) sepia(98%) saturate(3102%)
                                    hue-rotate(338deg) brightness(80%) contrast(93%);
                            }
                        }
                        p {
                            text-align: center;
                            height: 100px;
                            font-size: 15px;

                            margin: 0;
                            color: #495057;
                            letter-spacing: 1.3px;
                        }
                    }
                }
            }
        }

        .full-img {
            width: 90%;
            margin: 0 auto;
            img {
                width: 100%;
            }
        }
        .highlighted-words {
            margin-bottom: 2rem;
            q {
                color: #495057;
                opacity: 0.4;
                font-weight: 700;
                letter-spacing: 1.3px;
                font-size: 18px;
                text-align: justify;
            }
        }
        .list-item-section {
            background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
            border-radius: 5px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            height: fit-content;
            padding: 3rem 0;
            margin: 3rem 0;
            .title {
                h2 {
                    color: #fff;
                }
            }
            .list-items {
                ul {
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    li {
                        font-size: 18px;
                    }
                }
            }
        }

        .list {
            ul {
                list-style: none;
                padding: 0;

                li {
                    padding: 5px 0;
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    color: #495057eb;
                    font-size: 18px;
                }
            }
        }

        .cycle-process {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin: 2rem 0;
            .box {
                width: 18%;
                .overlay {
                    background-color: var(--secondaryColor);
                    width: 100%;
                    height: 18rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;
                    flex-direction: column;
                    border-radius: 5px;
                    position: relative;
                    overflow: hidden;

                    box-shadow: 0px 0px 69px -20px rgba(0, 0, 0, 0.3);

                    cursor: pointer;
                    .inner-box {
                        width: 100%;
                        z-index: 999;
                        &:nth-child(3) {
                            border: none;
                        }
                    }
                    hr {
                        margin: 0;
                        width: 100%;
                        background: #fff;
                        animation: none;
                        z-index: 999;
                    }
                    h6 {
                        color: #fff;
                        text-align: center;
                        font-weight: 400;
                        letter-spacing: 1.2px;
                        margin: 0;
                        z-index: 9;
                        transform: 0.4s;
                        transition-delay: 0s;
                        span {
                            font-size: 14px;
                        }
                    }
                    &:before,
                    &:after {
                        content: "";
                        width: 30%;
                        height: 25%;
                        position: absolute;
                        border-radius: 100%;
                        transition: 0.4s;
                    }
                    &:before {
                        transform: translate(-120px, 140px);
                        transition-delay: 0.2s;
                    }
                    &:after {
                        transform: scale(0);
                        transition-delay: 0s;
                    }
                    &:hover {
                        &:before {
                            transform: translate(0px);
                            transition-delay: 0s;
                        }
                        &:after {
                            width: 100%;
                            height: 100%;
                            transform: scale(1);
                            border-radius: 0;
                            transition-delay: 0.2s;
                        }
                    }
                }
                .name-tag {
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 25px;
                    h6 {
                        font-weight: 600;
                        color: #495057;
                    }
                }
                &:nth-child(1) {
                    .overlay {
                        &:before,
                        &:after {
                            background-color: #512fff;
                        }
                    }
                    // &:hover {
                    //     h6 {
                    //         color: #000;
                    //         transition-delay: 0.5s;
                    //     }
                    // }
                }
                &:nth-child(3) {
                    .overlay {
                        &:before,
                        &:after {
                            background-color: #9501d8;
                        }
                    }
                }
                &:nth-child(5) {
                    .overlay {
                        &:before,
                        &:after {
                            background-color: var(--primaryColor);
                        }
                    }
                }
            }
            hr {
                width: 10%;
                height: 2px;
                background: var(--secondaryColor);
                opacity: 0.7;
                position: relative;
                &::after {
                    content: "";
                    width: 50%;
                    height: 100%;
                    background-color: #fff;
                    position: absolute;
                    z-index: 99;
                    opacity: 1;
                }
                &:nth-child(2) {
                    &::after {
                        animation: run1 2s infinite;
                        @keyframes run1 {
                            0% {
                                left: -55%;
                            }
                            100% {
                                left: 100%;
                            }
                        }
                    }
                }
                &:nth-child(4) {
                    &::after {
                        animation: run2 2s infinite;
                        @keyframes run2 {
                            0% {
                                right: -55%;
                            }
                            100% {
                                right: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .long-list-out {
        margin: 3rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 30px;
            li {
                display: flex;
                cursor: default;
                align-items: center;
                justify-content: center;
                text-align: center;
                width: 10rem;
                height: 10rem;
                font-size: 14px;
                border-radius: 100%;
                background-color: #fff;
                transition: 0.4s;
                box-shadow: -10px -10px 20px -5px rgba(0, 0, 0, 0.39), -15px -15px 5px 10px rgba(255, 255, 255, 1);
                &:hover {
                    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.39);
                    transform: scale(1.2);
                    z-index: 99;
                }
            }
            &:nth-child(1) {
                li {
                    &:nth-child(1) {
                        background-color: var(--primaryColor);
                        z-index: 99;
                        color: #fff;
                        font-size: 20px;
                    }
                    &:nth-child(3),
                    &:nth-child(5) {
                        background: linear-gradient(-45deg, #fff 78%, var(--primaryColor) 0%);
                        &:hover {
                            background: var(--primaryColor);
                        }
                    }
                    &:nth-child(2),
                    &:nth-child(4),
                    &:nth-child(6) {
                        background: linear-gradient(-45deg, #fff 78%, var(--secondaryColor) 0%);
                        &:hover {
                            background: var(--secondaryColor);
                        }
                    }
                    &:hover {
                        color: #fff;
                    }
                }
            }
            &:nth-child(2) {
                li {
                    &:nth-child(1),
                    &:nth-child(3),
                    &:nth-child(5) {
                        background: linear-gradient(-45deg, #fff 78%, var(--secondaryColor) 0%);
                        &:hover {
                            background: var(--secondaryColor);
                        }
                    }
                    &:nth-child(2),
                    &:nth-child(4),
                    &:nth-child(6) {
                        background: linear-gradient(-45deg, #fff 78%, var(--primaryColor) 0%);
                        &:hover {
                            background: var(--primaryColor);
                        }
                    }
                    &:hover {
                        color: #fff;
                    }
                }
            }
            &:nth-child(3) {
                li {
                    &:nth-child(1),
                    &:nth-child(3),
                    &:nth-child(5) {
                        background: linear-gradient(-45deg, #fff 78%, var(--primaryColor) 0%);
                        &:hover {
                            background: var(--primaryColor);
                        }
                    }
                    &:nth-child(2),
                    &:nth-child(4),
                    &:nth-child(6) {
                        background: linear-gradient(-45deg, #fff 78%, var(--secondaryColor) 0%);
                        &:hover {
                            background: var(--secondaryColor);
                        }
                    }
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }
    .r-d {
        margin: 2rem auto;
    }
}
.tab-content.active {
    display: block;
}

// ------------------- Media Query -------------------

@media (max-width: 1300px) {
    .banner-video {
        height: 700px;
    }
    .offensive-section-two {
        .tab-content {
            .cycle-process {
                .box {
                    .overlay {
                        &:before {
                            transform: translate(-103px, 140px);
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1024px) {
    .banner-video {
        .title {
            h1 {
                text-align: center;
                font-size: 35px;
            }
        }
    }
    .offensive-section-two {
        .tab-button {
            video {
                top: -18rem;
            }
        }
        .tab-button {
            .box {
                .tab-link {
                    padding: 1rem;
                    h2 {
                        font-size: 22px;
                    }
                    p {
                        width: 90%;
                        margin: 0 auto;
                    }
                }
            }
        }
        .long-list-out {
            ul {
                li {
                    width: 8rem;
                    height: 8rem;
                    font-size: 13px;
                }
            }
        }

        .tab-content {
            .cycle-process {
                .box {
                    width: 24%;
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .banner-video {
        height: 450px;
        overflow: hidden;
    }
    .offensive-section-one {
        .full-img {
            width: 75%;
        }
    }
    .offensive-section-two {
        .tab-button {
            video {
                height: 50rem;
            }
            .box {
                .tab-link {
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
        .tab-content {
            .full-img {
                width: 100%;
            }
            .cycle-process {
                .box {
                    width: 35%;
                }
            }
        }
        .long-list-out {
            flex-direction: row;
            gap: 90px;
            ul {
                flex-direction: column;
                li {
                    width: 10rem;
                    height: 10rem;
                    font-size: 15px;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .banner-video {
        video {
            width: 45rem;
        }
        .title {
            h1 {
                font-size: 22px;
            }
        }
    }
    .offensive-section-one {
        .full-img {
            width: 100%;
        }
    }
    .offensive-section-two {
        // display: none;
        .tab-button {
            video {
                height: 75rem;
                width: 75rem;
            }
        }
        .tab-content {
            .cycle-process {
                flex-direction: column;
                gap: 30px;
                .box {
                    width: 55%;
                }
                hr.outer {
                    transform: rotate(90deg);
                }
            }
        }
        .long-list-out {
            flex-direction: column;
            gap: 10px;
        }
    }
}
@media (max-width: 400px) {
    .banner-video {
        overflow: hidden;

        .title {
            h1 {
                font-size: 22px;
            }
        }
    }
    .offensive-section-two {
        .tab-content {
            .icons-section {
                .icons-box {
                    gap: 30px;
                }
            }
        }
    }
}
