// Slider-Section
.company-slider {
  position: relative;

  .slider-text {
    position: absolute;
    top: 40%;

    h1 {
      color: #fff;
      text-align: center;
      font-weight: 700;
      font-size: 40px;
    }
  }
}

.about-section-block-one {
  .block-two-inn-one {
    background-image: url("../../../img/company/test-01.png");
    background-size: 65%;
    background-repeat: no-repeat;
    background-position: center;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 70% !important;
      }
    }
  }
  .center-align {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .block-two-inn-two {
    .according-section {
      width: 65% !important;

      .title {
        h2 {
          text-align: left;
          height: 5rem;
          display: flex;
          align-items: center;
          margin: 0;
          width: 120%;
          font-weight: 700;
          position: relative;
          color: #495057;
        }
      }
      .sub-title {
        h6 {
          color: var(--primaryColor);
          font-weight: 700;
        }
      }
      .accordion {
        .accordion-item {
          border: none;
          margin: 30px 0;
          .accordion-header {
            .accordion-button {
              border: unset;
              border-radius: 10px;
              background-color: transparent;
              outline: none;
              justify-content: center;
              padding: 15px 30px;
              box-shadow: 0px 17px 17px -11px rgba(0, 0, 0, 0.33);
              &:focus {
                box-shadow: 0px 17px 17px -11px rgba(0, 0, 0, 0.33);
                border: none !important;
              }
              &:not(.collapsed)::after {
                filter: brightness(0) invert(0);
              }
              h5 {
                color: #495057;
                font-weight: 700;
                margin: unset;
              }
            }
            .accordion-button:not(.collapsed) {
              box-shadow: 0px 17px 17px -11px rgba(0, 0, 0, 0.33);
              border: unset;
            }
          }
          .accordion-body {
            padding: 40px 20px;

            p {
              color: #495057eb;
            }
          }
        }
      }
    }
  }
}
.about-section-block-two {
  // height: 22rem;
  // padding: 3rem 0;
  .row {
    // justify-content: center;
    // align-items: center;
    .block-three-inn-one {
      position: relative;
      div {
        position: absolute;
        width: 30rem;
        top: -8rem;
        left: 10rem;
      }
    }
    .block-three-inn-two {
      .bloc-tabs {
        display: flex;
        width: 100%;
        border-bottom: 2px solid #c0c0c0;
      }
      .tabs {
        background-color: transparent;
        color: #495057;
        margin: 0 50px 25px 0;
        font-weight: 700;
        font-size: 18px;
      }
      .active-tabs {
        background: white;

        color: var(--primaryColor);
        position: relative;
        &::after {
          content: "";
          display: block;
          position: absolute;
          bottom: -27px;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          height: 2px;
          background: var(--primaryColor);
        }
      }
      button {
        border: none;
      }
      .content-tabs {
        width: 100%;
        // height: 100px;
      }
      .content {
        background: white;
        padding: 20px 0;

        height: 100%;
        display: none;
        font-size: 16px;
        line-height: 30px;
        transition: 0.4s;

        hr {
          width: 100px;
          height: 2px;
          background: #222;
          margin-bottom: 5px;
        }
        p {
          width: 100%;
          height: 100%;
          color: #495057eb;
          text-align: justify;
        }
      }
      .active-content {
        display: block;
      }
    }
  }
}
.about-section-block-three {
  display: flex;
  align-items: center;
  .swiper-slide {
    background-color: transparent;
  }
  height: 45rem;
  background-image: url("../../../img/company/about-banner-03.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .row {
    justify-content: center;
    .inn-one {
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      p {
        width: 80%;
        line-height: 30px;
        text-align: justify;
      }
    }
    .inn-two {
      .card {
        background-color: transparent;
        border: 0;
        cursor: grab;
        &:focus,
        :active {
          cursor: grabbing;
        }
        .one {
          border-bottom: 0px !important;
        }
        .one,
        .two {
          border: 1px solid rgba(255, 255, 255, 0.2);
          min-height: 200px;
          padding: 30px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 20px;
          img {
            width: 35%;
          }
          h5 {
            color: #fff;
            font-weight: 500;
            font-size: 18px;
          }
        }
      }
      .swiper {
        height: 30rem;
      }
      .swiper-slide:nth-child(1) .card .one,
      .swiper-slide:nth-child(1) .card .two,
      .swiper-slide:nth-child(3) .card .one,
      .swiper-slide:nth-child(3) .card .two {
        border-right: 0 !important;
      }
      .swiper-pagination-bullet {
        opacity: 1 !important;
        background-color: #fff !important;
      }
      .swiper-pagination-bullet-active {
        background-color: var(--primaryColor) !important;
      }
    }
  }
}

// ------------------------- Award ----------------------
.award-section-block {
  padding: 2rem 0;
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      text-align: center;
      height: 5rem;
      display: flex;
      width: 100%;
      font-weight: 700;

      justify-content: center;
      position: relative;
      color: #495057;
      &::after {
        content: "";
        width: 5%;
        height: 3px;
        border-radius: 20px;
        background-color: var(--primaryColor);
        position: absolute;
        bottom: 30px;
      }
    }
  }
  .awardsSwiper {
    height: 30rem;
    width: 70rem;
    .swiper-pagination-bullet {
      opacity: 1 !important;
      background-color: #000 !important;
    }
    .swiper-pagination-bullet-active {
      background-color: var(--primaryColor) !important;
    }
    .swiperCard {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      background-color: var(--secondaryColor);
      border-radius: 5px;
      padding: 25px 35px;

      position: relative;
      height: 20rem;
      cursor: grab;
      &:focus,
      :active {
        cursor: grabbing;
      }
      &::before {
        content: "";
        position: absolute;
        background-color: var(--primaryColor);
        top: 0;
        width: 0;
        height: 3px;
        border-radius: 50px;
        transition: 0.5s;
      }
      &:hover {
        &:before {
          width: 80%;
        }
        img {
          transform: rotateY(360deg);
          filter: brightness(0) invert(1);
        }
      }
      .slide-img {
        margin-left: -100px;
        margin-bottom: 40px;
        width: 100px;
        padding: 10px;
      }
      img {
        width: 100% !important;
        transition: 0.5s;
        min-height: 50px;
      }
      p {
        font-size: 16px;
        color: #fff;
        min-height: 100px;
        text-align: left;
      }
    }
  }
}
// ------------------------- Clients ----------------------
.clients-section-block {
  background-color: #122639;
  padding: 2rem 0 5rem 0;
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      text-align: center;
      height: 5rem;
      display: flex;
      width: 100%;
      font-weight: 700;

      justify-content: center;
      position: relative;
      color: #fff;
      &::after {
        content: "";
        width: 5%;
        height: 3px;
        border-radius: 20px;
        background-color: var(--primaryColor);
        position: absolute;
        bottom: 30px;
      }
      span {
        font-size: 20px;
        margin: 5px 0 0 30px;
        cursor: pointer;
      }
    }
  }
  .swiper-slide {
    background-color: transparent;
    img {
      width: 70%;
      opacity: 0.5;
      filter: grayscale(1);
      cursor: pointer;
      transition: 0.2s;
      &:hover {
        filter: grayscale(0);
        opacity: 1;
      }
    }
  }
  .pop-up {
    position: absolute;
    background-color: #495057eb;
    box-shadow: 0px 0px 122px -44px rgba(0, 0, 0, 0.31);
    z-index: 99;
    height: 24rem;
    width: 70rem;
    padding: 20px;
    transform: scale(0);
    top: -40px;

    .close-btn {
      cursor: pointer;
      font-size: 22px;
      color: #fff;
      // position: absolute;
      // right: 0;
      float: right;
    }
    img {
      width: 14%;
      padding: 20px;
    }
  }
  .pop-up.active {
    transform: scale(1);
  }
}
// ------------------------- Partners ----------------------
.partners-section-block {
  padding: 2rem 0 5rem 0;
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      text-align: center;
      height: 5rem;
      display: flex;
      width: 100%;
      font-weight: 700;

      justify-content: center;
      position: relative;
      color: #495057;
      &::after {
        content: "";
        width: 5%;
        height: 3px;
        border-radius: 20px;
        background-color: var(--primaryColor);
        position: absolute;
        bottom: 30px;
      }
      span {
        font-size: 20px;
        margin: 5px 0 0 30px;
        cursor: pointer;
      }
    }
  }

  .swiper-slide {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    img {
      width: 100% !important;
    }
    &:hover img:nth-child(1) {
      top: 0px;
    }
    &:hover img:nth-child(2) {
      top: 100px;
      opacity: 0;
    }
    &:hover img:nth-child(3) {
      opacity: 0;
    }
    img:nth-child(1) {
      position: absolute;
      top: -100px;
      width: 70%;
      transition: 0.4s;
    }
    img:nth-child(2) {
      position: absolute;
      top: 0;
      width: 70%;
      opacity: 0.4;
      transition: 0.4s;
    }
    img:nth-child(3) {
      width: 70%;
      opacity: 0;
    }
  }

  .pop-up {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 0px 122px -44px rgba(0, 0, 0, 0.31);
    z-index: 99;
    height: 22rem;
    width: 70rem;
    padding: 20px;
    transform: scale(0);

    .close-btn {
      cursor: pointer;
      font-size: 22px;
      color: var(--secondaryColor);
      z-index: 99;
      float: right;
      // margin-bottom: -5rem;
    }
    img {
      width: 19%;
      padding: 20px;
    }
  }
  .pop-up.active {
    transform: scale(1);
  }
}

// ------------------ Media Query ------------------

@media (max-width: 1025px) {
  .company-slider {
    .slider-text {
      h1 {
        font-size: 30px;
      }
    }
  }
  .clients-section-block {
    .swiper-slide {
      img {
        width: 90%;
      }
    }
  }
  .about-section-block-one {
    .block-two-inn-two {
      .according-section {
        width: 90% !important;
      }
    }
  }
  .about-section-block-two {
    .row {
      .block-three-inn-one {
        div {
          left: 0;
          top: -5rem;
        }
      }
    }
  }
  .award-section-block {
    .awardsSwiper {
      width: 59rem;
      .swiperCard {
        height: 22rem;
      }
    }
  }
  .clients-section-block {
    .pop-up {
      width: 50rem;
      img {
        width: 19%;
      }
    }
  }

  .partners-section-block {
    .pop-up {
      width: 50rem;
      img {
        width: 24%;
      }
    }
  }
}

@media (max-width: 800px) {
  .company-slider-section {
    height: 25rem;
    .slider-text {
      h1 {
        font-size: 24px;
      }
    }
  }
  .about-section-block-one {
    .block-two-inn-one {
      height: 18rem;
      background-size: 50%;
    }
    .block-two-inn-two {
      .according-section {
        margin: 0 auto;
        .title {
          h2 {
            width: 100%;
          }
        }
      }
    }
  }
  .about-section-block-two {
    .row {
      .block-three-inn-one {
        height: 16rem;
        div {
          left: 6.5rem;
          top: -5rem;
        }
      }
    }
  }
  .about-section-block-three {
    .row {
      .inn-one {
        p {
          width: 100%;
        }
      }
      .inn-two {
        .swiper {
          width: 85%;
        }
        .card {
          .one,
          .two {
            min-height: 155px;
            padding: 20px;
            img {
              width: 24%;
            }
          }
        }
      }
    }
  }
  .award-section-block {
    .awardsSwiper {
      width: 35rem;
    }
  }
  .clients-section-block {
    .swiper-slide {
      img {
        width: 100%;
      }
    }
    .pop-up {
      width: 40rem;
      height: fit-content;
      img {
        width: 24%;
      }
    }
  }

  .partners-section-block {
    .pop-up {
      width: 40rem;
      height: fit-content;
      img {
        width: 32%;
      }
    }
  }
}

@media (max-width: 500px) {
  .company-slider-section {
    .slider-text {
      h1 {
        font-size: 20px;
        width: 95%;
        margin: 0 auto;
      }
    }
  }
  .about-section-block-one {
    .block-two-inn-one {
      height: 18rem;
      background-size: 75%;
    }
  }
  .about-section-block-two {
    .row {
      .block-three-inn-one {
        div {
          left: 0;
          width: 25rem !important;
        }
      }
      .block-three-inn-two {
        .tabs {
          margin: 0 22px 25px 22px;
          font-size: 16px;
        }
      }
    }
  }

  .about-section-block-three {
    .row {
      .inn-two {
        .card {
          .one,
          .two {
            img {
              width: 55%;
            }
            h5 {
              height: 50px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .award-section-block {
    .awardsSwiper {
      width: 16rem;
    }
  }
  .clients-section-block {
    .title {
      h2 {
        span {
          display: none;
        }
      }
    }
  }
  .partners-section-block {
    .title {
      h2 {
        span {
          display: none;
        }
      }
    }
  }
}
@media (max-width: 400px) {
  .about-section-block-two {
    .row {
      .block-three-inn-two {
        .tabs {
          font-size: 14px;
        }
      }
      .block-three-inn-one {
        div {
          width: 22rem !important;
        }
      }
    }
  }
}
