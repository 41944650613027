.divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
    hr {
      width: 2px;
      background-color: #c81f3d;
      height: 25px !important;
      opacity: 1;
    }
  }