@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;1,700&display=swap");
@font-face {
  font-family: "ProximaNova";
  src: url("./fonts/Proxima\ Nova\ Alt\ Bold.otf");
}
@font-face {
  font-family: "ProximaNova";
  src: url("./fonts/Proxima\ Nova\ Alt\ Light.otf");
}
@font-face {
  font-family: "ProximaNova";
  src: url("./fonts/Proxima\ Nova\ Alt\ Thin.otf");
}
@font-face {
  font-family: "ProximaNova";
  src: url("./fonts/Proxima\ Nova\ Black.otf");
}
@font-face {
  font-family: "ProximaNova";
  src: url("./fonts/Proxima\ Nova\ Bold.otf");
}
@font-face {
  font-family: "ProximaNova";
  src: url("./fonts/Proxima\ Nova\ Extrabold.otf");
}
@font-face {
  font-family: "ProximaNova";
  src: url("./fonts/Proxima\ Nova\ Thin.otf");
}
@font-face {
  font-family: "ProximaNova";
  src: url("./fonts/ProximaNova-Regular.otf");
}



:root {
  --primaryColor: #c81f3d;
  --secondaryColor: #122639;
}

* {
  padding: 0;
  margin: 0;
  // scroll-behavior: smooth;
  font-family: "ProximaNova";
}
body {
  overflow-x: hidden;
}
a {
  outline: none;
}

/* Swiper Js */
.swiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
