// ---------------- Slider-Section ----------------
.managed-slider {
  position: relative;

  .slider-text {
    position: absolute;
    top: 40%;

    h1 {
      color: #fff;
      text-align: center;
      font-weight: 700;
      font-size: 45px;
    }
  }
  img {
    filter: grayscale(0.5);
  }
}

// ---------------- Section One ----------------
.section-one-block-one {
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      text-align: center;
      height: 5rem;
      display: flex;
      width: 100%;
      font-weight: 700;

      justify-content: center;
      position: relative;
      color: #495057;
      &::after {
        content: "";
        width: 5%;
        height: 3px;
        border-radius: 20px;
        background-color: var(--primaryColor);
        position: absolute;
        bottom: 30px;
      }
    }
  }
  .center-align {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content {
    p {
      text-align: justify;
      width: 100%;
      color: #495057eb;
      font-size: 18px;
      line-height: 35px;
    }
  }
  .sub-title {
    margin-bottom: 2rem;
    h5 {
      text-align: center;
      font-weight: 700;
      color: #495057;
    }
  }
  .icon-section {
    .icons {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      gap: 50px;
      .box {
        display: flex;
        flex-direction: column;

        .inner-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 20px;
          span {
            &:nth-child(1) {
              display: flex;

              align-items: center;
              justify-content: center;
              width: 100px;
              margin: 0 auto;
              img {
                width: 55%;
                filter: brightness(0) saturate(100%) invert(20%) sepia(98%) saturate(3102%) hue-rotate(338deg)
                  brightness(80%) contrast(93%);
              }
            }

            h6 {
              text-align: center;
              height: 100px;
              font-size: 15px;

              margin: 0;
              color: #495057;
              letter-spacing: 1.3px;
            }
          }
        }
      }
    }
  }
}

// ---------------- Section Two ----------------
.section-two-block-one {
  .tab-nav {
    margin-bottom: 5rem;
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      gap: 60px;
      li {
        position: relative;

        // a {
        text-decoration: none;
        color: var(--primaryColor);
        font-weight: 700;
        cursor: pointer;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 3px;
          background-color: var(--primaryColor);
          border-radius: 5px;
          opacity: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.4s;
        }
        // }
      }
      li.active {
        // a {
        &::after {
          bottom: -10px;
          opacity: 1;
        }
        // }
      }
    }
  }
  .content-block {
    display: none;
    margin-bottom: 5rem;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      h2 {
        text-align: center;
        height: 5rem;
        display: flex;
        width: 100%;
        font-weight: 700;

        justify-content: center;
        position: relative;
        color: #495057;
        &::after {
          content: "";
          width: 5%;
          height: 3px;
          border-radius: 20px;
          background-color: var(--primaryColor);
          position: absolute;
          bottom: 30px;
        }
      }
    }
    .center-align {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .content {
      p {
        text-align: justify;
        width: 100%;
        color: #495057eb;
        font-size: 18px;
        line-height: 35px;
      }
    }

    .highlighted-words {
      text-align: center;
      width: 85%;
      margin: 0 auto 2rem auto;
      q {
        color: #495057;
        opacity: 0.4;
        font-weight: 700;
        letter-spacing: 1.3px;
        font-size: 22px;
      }
    }

    .icon-section {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      gap: 50px;
      .box {
        display: flex;
        flex-direction: column;

        .inner-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 20px;
          span {
            &:nth-child(1) {
              display: flex;

              align-items: center;
              justify-content: center;
              width: 100px;
              margin: 0 auto;
              img {
                width: 55%;
                filter: brightness(0) saturate(100%) invert(20%) sepia(98%) saturate(3102%) hue-rotate(338deg)
                  brightness(80%) contrast(93%);
              }
            }

            h6 {
              text-align: center;
              height: 100px;
              font-size: 15px;
              width: 170px;
              margin: 0;
              color: #495057;
              letter-spacing: 1.3px;
            }
          }
        }
      }
      .box.additional-icon {
        height: 22rem;
      }
    }
    .icon-section.no-gap {
      gap: 0;
    }

    .icon-section.center-align {
      justify-content: center;
      gap: 19%;
    }
    .sub-title {
      margin-bottom: 2rem;
      h5 {
        text-align: center;
        font-weight: 700;
        color: #495057;
      }
    }
    .sub-icon-section {
      width: 80%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 30px;
      .box {
        display: flex;
        align-items: center;
        gap: 20px;
        span {
          &:nth-child(1) {
            width: 70px;
            img {
              width: 100%;
              filter: brightness(0) saturate(100%) invert(20%) sepia(98%) saturate(3102%) hue-rotate(338deg)
                brightness(80%) contrast(93%);
            }
          }
          &:nth-child(2) {
            width: 100%;
            p {
              color: #495057;
              margin: 0;
            }
          }
        }
      }
    }
    .step-process {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5rem;
      .box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--primaryColor);
        width: 150px;
        height: 50px;
        border-radius: 5px;
        overflow: hidden;
        &::before {
          content: "";
          background-color: #fff;
          opacity: 0.4;
          width: 30px;
          height: 180px;
          position: absolute;
          animation: slide 3s infinite;
          @keyframes slide {
            0% {
              transform: rotate(45deg) translateX(-100px);
            }

            100% {
              transform: rotate(45deg) translateX(100px);
            }
          }
        }
        h5 {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 1.3px;
          margin: 0;
          z-index: 9;
        }
      }

      span {
        width: 50px;
        height: 2px;
        background-color: var(--secondaryColor);
      }
    }

    .cycle-process {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin-bottom: 5rem;
      .box {
        width: 18%;
        .overlay {
          background-color: var(--primaryColor);
          width: 100%;
          height: 15rem;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          border-radius: 5px;
          position: relative;
          overflow: hidden;
          gap: 20px;
          cursor: pointer;
          .inner-box {
            width: 100%;
            z-index: 999;
            &:nth-child(3) {
              border: none;
            }
          }
          hr.inner-line {
            margin: 0;
            width: 100%;
            height: 2px;
            background: var(--secondaryColor);
            opacity: 0.7;
            background: #fff;
            z-index: 999;
          }
          h6 {
            color: #fff;
            text-align: center;
            font-weight: 400;
            letter-spacing: 1.2px;
            margin: 0;
            z-index: 9;
            span {
              font-size: 14px;
            }
          }
          &:before,
          &:after {
            content: "";
            width: 25%;
            height: 25%;
            position: absolute;
            background-color: var(--secondaryColor);
            border-radius: 100%;
            transition: 0.4s;
          }
          &:before {
            transform: translate(-120px, 120px);
            transition-delay: 0.2s;
          }
          &:after {
            transform: scale(0);
            transition-delay: 0s;
          }
          &:hover {
            &:before {
              transform: translate(0px);
              transition-delay: 0s;
            }
            &:after {
              width: 100%;
              height: 100%;
              transform: scale(1);
              border-radius: 0;
              transition-delay: 0.2s;
            }
          }
        }
        .name-tag {
          margin-top: 10px;
          h6 {
            font-weight: 600;
            color: #495057;
          }
        }
      }
      hr.outer-line {
        width: 10%;
        height: 2px;
        background: var(--secondaryColor);
        opacity: 0.7;
        position: relative;
        &::after {
          content: "";
          width: 50%;
          height: 100%;
          background-color: #fff;
          position: absolute;
          z-index: 99;
          opacity: 1;
          animation: run 2s infinite;
          @keyframes run {
            0% {
              left: -55%;
            }
            100% {
              left: 100%;
            }
          }
        }
      }
    }
    .name-tag {
      h6 {
        text-align: center;
      }
    }
    .formula {
      box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.17);
      width: fit-content;
      margin: 0 auto;
      padding: 10px 25px;
      h5 {
        color: #495057;
        text-align: center;
        margin: 0;
      }
    }
  }
  .content-block.active {
    display: block;
  }
  .full-image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;

    img {
      width: 85%;
    }
  }
}

// ------------------------ Media query ------------------------

@media (max-width: 1025px) {
  .managed-slider {
    height: 25rem;
    .slider-text {
      h1 {
        font-size: 35px;
      }
    }
  }

  .section-two-block-one {
    .tab-nav {
      ul {
        gap: 30px;
        padding: 0;
        li {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    .content-block {
      .cycle-process {
        .box {
          .overlay {
            &::before {
              transform: translate(-80px, 120px);
            }
          }
        }
      }
      .icon-section {
        gap: 25px;
      }
      .icon-section.no-gap {
        justify-content: start;

        .box {
          width: 118px;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .managed-slider {
    .slider-text {
      h1 {
        font-size: 25px;
      }
    }
  }
  .section-two-block-one {
    .tab-nav {
      width: 80%;
      margin: 0 auto;
      ul {
        gap: 25px;
        flex-wrap: wrap;
        // justify-content: flex-start;
        li {
          a {
            font-size: 14px;
          }
        }
      }
    }
    .content-block {
      .cycle-process {
        .box {
          width: 25%;
          .overlay {
            &::before {
              transform: translate(-85px, 120px);
            }
          }
        }
      }
      .step-process {
        .box {
          h5 {
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
      .icon-section.no-gap {
        justify-content: space-evenly;
      }
    }
  }
  .section-two-block-one {
    .full-image {
      img {
        width: 65%;
      }
    }
    .full-image.full-length {
      img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 500px) {
  .section-one-block-one {
    .icon-section {
      .icons {
        gap: 30px;
        .box {
          .inner-box {
            span {
              &:nth-child(1) {
                img {
                  width: 50%;
                }
              }

              h6 {
                font-size: 13px;
                height: 75px;
              }
            }
          }
        }
      }
    }
  }
  .section-two-block-one {
    .tab-nav {
      ul {
        // flex-direction: column;
        gap: 15px;
      }
    }
    .full-image {
      img {
        width: 100%;
      }
    }
    .content-block {
      .icon-section {
        gap: 0;
        .box {
          .inner-box {
            span {
              &:nth-child(1) {
                img {
                  width: 50%;
                }
              }
              h6 {
                width: 100px;
                font-size: 13px;
              }
            }
          }
        }
      }
      .cycle-process {
        flex-direction: column;
        gap: 20px;
        .box {
          width: 60%;
          .overlay {
            &::before {
              transform: translate(-120px, 120px);
            }
          }
        }
        hr.outer-line {
          transform: rotate(90deg);
        }
      }
      .step-process {
        flex-direction: column;
        gap: 20px;
        margin-bottom: 0;
        span {
          width: 24px;
          transform: rotate(90deg);
        }
        .box {
          h5 {
            font-size: 14px;
          }
        }
      }
    }
  }
}
