.stickyForm {
    position: fixed;
    right: -480px;
    top: 8%;
    z-index: 999;
    transition: all 0.6s ease;
    .openbtn {
        background-color: #c81f3d;
        padding: 5px 0 5px 15px;
        transition-delay: 0.1s;
        text-transform: uppercase;
        transform: rotate(90deg) translate(150%, 800%);
        border-radius: 0 0 10px 10px;
        width: 50%;
        margin: 0 auto;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.42);
        cursor: pointer;
        h6 {
            text-orientation: sideways;
            color: #fff;
            margin: 0;
            font-weight: 700;
            font-size: 14px;
            letter-spacing: 1.2px;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                padding: 0 20px;
                font-size: 20px;
                transform: rotate(-90deg);
            }
        }
    }
    .contactfrom {
        background-color: #fff;
        padding: 25px;
        overflow: hidden;
        .closebtn {
            position: absolute;
            right: 32px;
            top: 58px;
            font-size: 20px;
            font-weight: 700;
            cursor: pointer;
        }
        .name {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            input {
                margin: 7px 0;
                border: 1px solid #ced1dc;
                padding: 0 10px;
                outline: none;
                height: 50px;
                width: 100%;
                &::placeholder {
                    color: #999fb7;
                }
            }
        }
        .num {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            input {
                margin: 7px 0;
                border: 1px solid #ced1dc;
                padding: 0 10px;
                outline: none;
                height: 50px;
                width: 100%;
                &::placeholder {
                    color: #999fb7;
                }
            }
        }
        .mail {
            input {
                margin: 7px 0;
                border: 1px solid #ced1dc;
                padding: 0 10px;
                outline: none;
                height: 50px;
                width: 100%;
                &::placeholder {
                    color: #999fb7;
                }
            }
        }
        .services {
            select {
                margin: 7px 0;
                border: 1px solid #ced1dc;
                padding: 0 10px;
                outline: none;
                height: 50px;
                width: 100%;
                color: #999fb7;
                option {
                    color: #000;
                    // &:nth-child(1) {
                    //     color: #999fb7;
                    // }
                }
                &:focus,
                &:active {
                    color: #000;
                }
            }
        }
        .message {
            textarea {
                margin: 7px 0;
                border: 1px solid #ced1dc;
                padding: 0 10px;
                outline: none;
                width: 100%;
                &::placeholder {
                    color: #999fb7;
                }
            }
        }
        .contactby-option {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            span {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                font-size: 14px;
            }
        }
        .submit-button {
            input {
                width: 100%;
                height: 3rem;
                border: 0;
                text-align: center;
                color: #ffffff;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 0.03rem;
                font-size: 0.875rem;
                background-color: #c81f3d;
            }
        }
        .policy {
            color: #999fb7;
            font-size: 14px;
            text-decoration: none;
            margin-top: 20px;
        }
    }
}
.stickyForm.active {
    right: 0;

    .openbtn {
        transform: rotate(90deg) translate(150%, 700%);
        transition-delay: 0.1s;
    }
}
form {
    .success-message {
        display: none;
    }
    .success-message.active {
        display: block;
        h4 {
            background: #fff;
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 22px;
            padding: 0 2rem;
            text-align: center;
            span {
                padding: 10px 15px;

                font-size: 18px;
                color: #ffffff;
                border-radius: 5px;
                cursor: pointer;
                background-color: var(--secondaryColor);
            }
        }
    }
}

@media (max-width: 500px) {
    .stickyForm.active {
        right: -10px;
    }
}
